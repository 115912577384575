import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import Overline from '../Typography/Overline';
import Headline5 from '../Typography/Heading/Headline5';
import Body2 from '../Typography/Body/Body2';
import truncateLines from '../TruncateLines';
import breakpoints from '../Theme/breakpoints';
import { topRoundedCorners, mediumRoundedCorners } from '../RoundedCorners';

export { default as Card } from '@mui/material/Card';

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const CardOverlineWrapper = styled.div`
  height: 18px;
  position: relative;
  & > * {
    position: absolute;
    left: 1px;
  }
`;

const CardOverlineContent = styled(Overline)`
  && {
    height: 18px;
    display: flex;
    align-items: center;
  }
`;

const CardOverlineSuffix = styled.span`
  color: var(--black-inactive);
  display: inline-block;
  padding-left: 0.3rem;
`;

const cardOverlinePropertyTypes = {
  children: PropTypes.node.isRequired,
};

type CardOverlinePropertyTypes = InferProps<typeof cardOverlinePropertyTypes>;

const CardOverline = ({ children } : CardOverlinePropertyTypes) => (
  <CardOverlineWrapper>
    <CardOverlineContent>
      { children }
    </CardOverlineContent>
  </CardOverlineWrapper>
);

const StyledHeading = styled(Headline5)<
{
  tag?: string;
}
>`
  ${truncateLines};

  && {
    margin: 0 0 8px;
  }
`;

const cardHeadingPropertyTypes = {
  children: PropTypes.node,
  tag: PropTypes.string,
};

type CardHeadingPropertyTypes = InferProps<typeof cardHeadingPropertyTypes> & {
  tag: React.ComponentType | React.ElementType;
};

const CardHeading = ({
  children, tag,
} : CardHeadingPropertyTypes) => (
  <StyledHeading tag={tag}>
    {children}
  </StyledHeading>
);

const CardBodyText = styled(Body2)`
  ${truncateLines};
  && {
    font-family: var(--font-article);
  }
`;

const StyledBody2 = styled(Body2)`
  && {
    margin: 8px 0 0;
    font-family: var(--font-article);
    font-weight: normal;
  }
`;

const cardDatePropertyTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

type CardDatePropertyTypes = InferProps<typeof cardDatePropertyTypes> & {
  className?: string;
};

const CardDate = ({
  children,
  className,
  ...properties
} : CardDatePropertyTypes) => (
  <StyledBody2
    className={className}
    variant="caption"
    {...properties}
  >
    {children}
  </StyledBody2>
);

const RelativeContainer = styled.div<
{
  aspectRatioPadding?: string;
  isCardImage?: boolean;
}
>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: start;
  overflow: hidden;
  height: 0;
  padding-bottom: ${
  (properties) => properties.aspectRatioPadding ?? '66.67%'
};
  ${(properties) => (properties.isCardImage
    ? topRoundedCorners : mediumRoundedCorners)
};
`;

const featuredMarking = css`
  ::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-bottom: 3px solid var(--primary-color);
  }
`;

const PortraitImageContainer = styled(RelativeContainer)`
  width: 138px;  
  height: 184px;
  padding-bottom: 0;
`;

const FullWidthImageContainer = styled(RelativeContainer)<
{
  logoLetterbox?: boolean;
  withPageMargins?: boolean;
  featured?: boolean;
}
>`
  padding-bottom: ${({ logoLetterbox }) => (
    logoLetterbox ? '18px' : 'calc(66.67% + 18px)'
  )};
  width: calc(100% + ${({
    withPageMargins,
  }) => (withPageMargins ? '32' : '24')}px);
  height: ${({ logoLetterbox }) => (
    logoLetterbox ? '212px' : '0'
  )};
  left: -16px;

  ${({ featured }) => featured && featuredMarking}
  @media (min-width: ${breakpoints.sm}px) {
    padding-bottom: ${({ logoLetterbox }) => (
    logoLetterbox ? '0' : '66.67%'
  )};
    align-items: ${({ logoLetterbox }) => (
    logoLetterbox ? 'center' : 'start'
  )};
    left: auto;
    width: auto;
  }
`;

const CardTextContainer = styled.div`
  padding: 24px 0 0;

  @media(min-width: ${breakpoints.lg}px) {
    padding: 16px 0 0;
  }
`;

export {
  CardOverline,
  CardOverlineWrapper,
  CardOverlineContent,
  CardOverlineSuffix,
  CardHeading,
  CardBodyText,
  CardContainer,
  CardTextContainer,
  RelativeContainer,
  FullWidthImageContainer,
  CardDate,
  PortraitImageContainer,
};
